<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">행사 {{ modalModeText }}</h6>
    </template>

    <card type="secondary" body-classes="px-lg-5">
      <form role="form"> 
        <div class="form-group mt-2">
          <base-input 
            type="date-local"
            label="행사명"
            name="title"
            placeholder="행사명"
            v-model="newData.title"
          />
        </div> 
        <div class="form-group mt-2">
          <base-input 
            type="date-local"
            label="행사장소"
            name="place"
            placeholder="행사장소"
            v-model="newData.place"
          />
        </div>
        <div class="form-group mt-2">
          <base-input 
            type="date-local"
            label="주관단체"
            name="organization"
            placeholder="주관단체"
            v-model="newData.organization"
          />
        </div>
        <div class="form-group mt-2">
          <base-input 
            type="date-local"
            label="주관부서"
            name="dept_cd"
            placeholder="주관부서"
            v-model="newData.dept_cd"
          />
        </div>
        <div class="form-group mt-2">
          <base-input 
            type="datetime-local"
            label="행사일"
            name="open_dt"
            placeholder="행사일"
            v-model="newData.open_dt"
          />
        </div>
        <div class="form-group mt-2">
          <base-input 
            type="datetime-local"
            label="출입 시작 날짜"
            name="valid_dt"
            placeholder="출입 시작 날짜"
            v-model="newData.valid_dt"
          />
        </div>
        <div class="form-group mt-2">
          <base-input 
            type="datetime-local"
            label="출입 종료 날짜"
            name="expire_dt"
            placeholder="출입 종료 날짜"
            v-model="newData.expire_dt"
          />
        </div>
        <div class="form-group mt-2" >
          <label class="form-control-label">문자안내문</label>
          <textarea 
            class="form-control"
            v-model="newData.resultContent"
            rows="5"
            placeholder="(예시)주차안내 : 기계식 주차장은 입출차에 30분 이상 소요 될 수 있으니 충분한 시간을 가지고 방문하시기 바랍니다. 또한 총 중량 2175kg이하 차량만 입고 가능합니다.
주관단체"
          ></textarea>
        </div> <div class="form-group mt-2" >
          <label class="form-control-label">인증안내문</label>
          <textarea 
            class="form-control"
            v-model="newData.resultContent"
            rows="5"
            placeholder="(예시)지하 2층으로 내려가세요."
          ></textarea>
        </div>
         
        <div class="row">&nbsp;</div>

        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin.vue";
import * as api from "@/api";
import PresetSelect from "@/components/Inputs/PresetSelect";

export default {
  components: {
    Modal,
    PresetSelect,
  },
  mixins: [EditModalMixin],
  data() {
    return {
      newData: {
        place: "",
        title: "", 
      }, 
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    
    // 등록하기
    onAdd() {    
        const data = JSON.parse(JSON.stringify(this.newData));
        api.post("service/myinformation/entrywow", { data }).then((response) => { 
          data.inv_id = response.data.msg;
          api.post("service/myinformation/entry", { data }).then(() => {
            this.$emit("addDone", data);
            this.hideModal();
          }); 
        }); 
       
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData)); 
      
      api.put(`service/myinformation/entrywow/${row.inv_id}`, { data }).then(() => { 
        api.put(`service/myinformation/entry/${row.id}`, { data }).then(() => {
          this.$emit("editDone", row, data);
          this.hideModal();
        });
      });
     
    },
    // 등록창 열기
    handleAdd() {
      this.newData.title = "";
      this.newData.place= ""; 
      this.newData.open_dt = "";
      this.newData.valid_dt = "";
      this.newData.expire_dt = "";
      this.newData.organization = "";
      this.newData.dept_cd = ""; 
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.newQuestion = "";
      this.loadModal(
        `service/myinformation/entry/${row.id}`,
        row,
        (data) => {
          return {
            title: data.title,
            place: data.place, 
            open_dt: data.open_dt2,
            valid_dt: data.valid_dt2,
            expire_dt: data.expire_dt2,
            organization: data.organization,
            dept_cd: data.dept_cd,
          };
        }
      );
    },
  },
};
</script>
