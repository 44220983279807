<!-- 내 문의사항 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6>내문의사항</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col"></div>

                <div class="col text-right">
                  <!-- New Button -->
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+행사등록</base-button
                  >
                  <!-- End New Button -->
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->
            <!-- Preview Modal -->
            <modal v-model:show="showPreviewModal" size="lg">
              <template v-slot:header>
                <h6 class="modal-title">
                  <div><p>문의 내용</p></div>
                </h6>
              </template>
              <div class="text-left" v-html="question"></div>
              <template v-slot:footer>
                <base-button
                  type="link"
                  class="ml-auto"
                  @click="showPreviewModal = false"
                  >Close</base-button
                >
              </template>
            </modal>
            <!-- End Preview Modal -->
            <!-- modal -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'createdTime', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="순서"
                sortable
                min-width="50px"
                prop="no"
              >
              </el-table-column>
              <el-table-column
                label="등록회원"
                sortable
                min-width="100px"
                prop="write_name"
              >
              </el-table-column>
              <el-table-column
                label="행사장소"
                sortable
                min-width="150px"
                prop="place"
              >
              </el-table-column>
              <el-table-column
                label="행사일자"
                sortable
                min-width="100px"
                prop="open_date"
              >
              </el-table-column>
              <el-table-column
                label="행사시간"
                sortable
                min-width="100px"
                prop="open_time"
              >               
              </el-table-column>
              <el-table-column
                label="행사명"
                sortable
                min-width="150px"
                prop="title"
              > 
              </el-table-column>
              <el-table-column
                label="주관단체"
                sortable
                min-width="100px"
                prop="organization"
              >
              </el-table-column>
              <el-table-column
                label="주관부서"
                sortable
                min-width="100px"
                prop="dept_cd"
              >
              </el-table-column>
              <el-table-column
                label="손님수"
                sortable
                min-width="100px"
                prop="guest_number"
              >
              <template v-slot="{ row }">
                  <span class="row text-left mx-0 px-0">
                    <div class="col font-weight-900 text-blue mx-0 px-0">
                      {{ row.guest_number }}   
                      <base-button
                    class="edit mx-0 px-0"
                    type="primary"
                    size="sm"
                    @click.stop="goGuest(row)"
                  >
                    추가
                  </base-button>                  
                    </div>
                
                  </span>
                  
                </template>
              </el-table-column>
              <el-table-column
                label="등록일"
                sortable
                min-width="150px"
                prop="createdDate"
              >
              </el-table-column>
               
              <el-table-column
                label="action"
                min-width="130px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex">
                  <base-button
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="handleEdit(row)"
                  >
                    수정
                  </base-button>
                  <base-button
                    class="remove btn-link text-white"
                    type="danger"
                    size="sm"
                    @click.stop="handleDelete(row)"
                  >
                     삭제 
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/EditModalEntry.vue";
import Modal from "@/components/Modal";
import * as api from "@/api";
import moment from "moment";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
    Modal,
  },
  // 데이터 객체 추가
  data() {
    return {
      tableData: [], // 레코드셋
      newData: {
        // 새 등록 데이터
        id: 0,
        question: "",
        questPart: "",
      },
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      showPreviewModal: false,
      edit: false,
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },

  // 함수 추가
  methods: {
    // api 호출
    getDataFromApi(page = 1) {
      // const url = `service/myinformation/entrywow?page=${page}`;
      // api.get(url).then((response) => {
      //   if (!api.isSuccess(response)) {
      //     return;
      //   } 
      // });
      const url2 = `service/myinformation/entry?page=${page}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data; 
        if (data.rows > 0) {
          for (const row of d) {
            row.open_date = moment(row.opon_dt).format("YYYY-MM-DD");
            row.open_time = moment(row.opon_dt).format("HH:mm");
            row.createdDate = moment(row.createdTime).format("YYYY-MM-DD");
          }
        }
        this.tableData = d;
        this.total = data.total;
      });
    }, 
    // 등록 모달
    goGuest(row) {
      this.$router.push({
        name: "손님관리",
        query: {
          suid: row.id,
          inv_id: row.inv_id,
        },
      });
    },
    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 삭제하기
    handleDelete(row) {
      if (confirm("삭제하시겠습니까?")) {
       api.delete_(`service/myinformation/entrywow/${row.inv_id}`).then(() => { 
          api.delete_(`service/myinformation/entry/${row.id}`).then(() => { 
         this.getDataFromApi();
        });
       }); 
      }
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
