<!-- 내 문의사항 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-blue d-inline-block mb-0">내문의사항</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-sm-2">
                  <preset-select
                    label="지부"
                    name="local"
                    placeholder="지부"
                    v-model="query.local"
                    :choices="LOCAL2.map((x) => [x, `${x}`])"
                    @change="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2 mt-3">
                  <base-input
                    @change="getDataFromApi(1)"
                    placeholder="이름"
                    v-model="query.name"
                    @keyup.enter.prevent="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-1 mb-2">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click.prevent="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
                <div class="col-sm-1 mb-2">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click.prevent="reset()"
                    >초기화</base-button
                  >
                </div>
                <div class="col text-right">
                  <!-- New Button -->
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+추가</base-button
                  >
                  <!-- End New Button -->
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'createdTime', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column label="no" sortable min-width="50px" prop="no">
              </el-table-column>
              <el-table-column
                label="지부"
                sortable
                min-width="100px"
                prop="local"
              >
              </el-table-column>
              <el-table-column label="이름" min-width="150px" prop="name">
              </el-table-column>
              <el-table-column
                label="회원번호"
                sortable
                min-width="150px"
                prop="memberNo"
              >
              </el-table-column>
              <el-table-column
                label="email"
                sortable
                min-width="100px"
                prop="email"
              >
              </el-table-column>
              <el-table-column
                label="문의"
                sortable
                min-width="100px"
                prop="role"
              >
              </el-table-column>
              <el-table-column
                label="나누기"
                sortable
                min-width="100px"
                prop="shareRole"
              >
              </el-table-column>
              <el-table-column
                label="공지"
                sortable
                min-width="100px"
                prop="noticeRole"
              >
              </el-table-column>

              <el-table-column
                label="action"
                min-width="130px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex">
                  <base-button
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="handleEdit(row)"
                  >
                    수정
                  </base-button>
                  <base-button
                    class="remove btn-link text-white"
                    type="danger"
                    size="sm"
                    @click.stop="handleDelete(row)"
                  >
                     삭제
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/EditModalManager.vue";
import * as api from "@/api";
import PresetSelect from "@/components/Inputs/PresetSelect";
import { LOCAL2 } from "@/consts/class";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    PresetSelect,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
  },
  // 데이터 객체 추가
  data() {
    return {
      LOCAL2,
      tableData: [], // 레코드셋
      newData: {
        // 새 등록 데이터
        id: 0,
      },
      query: {
        local: "",
        name: "",
      },
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },

  // 함수 추가
  methods: {
    // api 호출
    getDataFromApi(page = 1) {
      this.loading = true;
      if (this.query.local === "전체") {
        this.query.local = "";
      }
      const url = `service/myinformation/manager?page=${page}&name=${this.query.name}&local=${this.query.local}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        this.tableData = d;
        this.total = data.total;
      });
    },

    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 삭제하기
    handleDelete(row) {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`service/myinformation/manager/${row.id}`).then(() => {
          this.tableData = this.tableData.filter((r) => r.id !== row.id);
        });
      }
    },
    reset() {
      this.query.name = "";
      this.query.local = "";
      this.getDataFromApi(1);
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
