<!-- 내 문의사항 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6>내문의사항</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col"></div>

                <div class="col text-right">
                  <!-- New Button -->
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+질문추가</base-button
                  >
                  <!-- End New Button -->
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->
            <!-- Preview Modal -->
            <modal v-model:show="showPreviewModal" size="lg">
              <template v-slot:header>
                <h6 class="modal-title">
                  <div><p>문의 내용</p></div>
                </h6>
              </template>
              <div class="text-left" v-html="question"></div>
              <template v-slot:footer>
                <base-button
                  type="link"
                  class="ml-auto"
                  @click="showPreviewModal = false"
                  >Close</base-button
                >
              </template>
            </modal>
            <!-- End Preview Modal -->
            <!-- modal -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'createdTime', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="요청일"
                sortable
                min-width="130px"
                prop="createdTime"
              >
              </el-table-column>
              <el-table-column
                label="업무대분류"
                sortable
                min-width="100px"
                prop="questPart"
              >
              </el-table-column>
              <el-table-column
                label="업무소분류"
                sortable
                min-width="100px"
                prop="questPart2"
              >
              </el-table-column>
              <el-table-column
                label="문의내용"
                min-width="150px"
                prop="question2"
              >
                <template v-slot="{ row }">
                  <span class="clickable" @click="showPreview(row)">
                    <div class="font-weight-600">
                      {{ row.question2 }}
                    </div>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="답변내용"
                sortable
                min-width="150px"
                prop="resultContent2"
              >
                <template v-slot="{ row }">
                  <span class="clickable" @click="showPreview2(row)">
                    <div class="font-weight-600">
                      {{ row.resultContent2 }}
                    </div>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="결과"
                sortable
                min-width="100px"
                prop="result"
              >
              </el-table-column>
              <el-table-column
                label="처리일시/처리자"
                sortable
                min-width="150px"
                prop="lastUpdated"
              >
              </el-table-column>
              <el-table-column
                label="action"
                min-width="130px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex" v-if="row.edit">
                  <base-button
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="handleEdit(row)"
                  >
                    수정
                  </base-button>
                  <base-button
                    class="remove btn-link text-white"
                    type="danger"
                    size="sm"
                    @click.stop="handleDelete(row)"
                  >
                     삭제 
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/EditModal.vue";
import Modal from "@/components/Modal";
import * as api from "@/api";
import moment from "moment";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
    Modal,
  },
  // 데이터 객체 추가
  data() {
    return {
      tableData: [], // 레코드셋
      newData: {
        // 새 등록 데이터
        id: 0,
        question: "",
        questPart: "",
      },
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      showPreviewModal: false,
      edit: false,
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },

  // 함수 추가
  methods: {
    // api 호출
    getDataFromApi(page = 1) {
      this.loading = true;
      const url = `service/myinformation/question?page=${page}&type=0`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            row.createdTime = moment(row.createdTime).format(
              "YYYY-MM-DD HH:mm"
            );
            if (
              row.result === "접수확인" ||
              row.result === "처리완료" ||
              row.result === "질문보완요청"
            ) {
              row.lastUpdated =
                moment(row.lastUpdated).format("YYYY-MM-DD HH:mm") +
                "/" +
                row.resolver;
            } else {
              row.lastUpdated = "";
            }
            if (row.question.length > 30) {
              row.question2 = row.question.substr(0, 30) + "...";
            } else {
              row.question2 = row.question;
            }
            if (row.resultContent && row.resultContent.length > 20) {
              row.resultContent2 = row.resultContent.substr(0, 20) + "...";
            } else {
              row.resultContent2 = row.resultContent;
            }
            if (row.result === "등록" || row.result === "질문보완요청") {
              row.edit = true;
            } else {
              row.edit = false;
            }
            if (row.question) {
              row.question = row.question.split("\n").join("<br>");
            }
            if (row.resultContent) {
              row.resultContent = row.resultContent.split("\n").join("<br>");
            }
          }
        }
        this.tableData = d;
        this.total = data.total;
      });
    },
    showPreview(row) {
      this.question = row.question;
      this.showPreviewModal = true;
    },
    showPreview2(row) {
      this.question = row.resultContent;
      this.showPreviewModal = true;
    },
    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 삭제하기
    handleDelete(row) {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`service/myinformation/question/${row.id}`).then(() => {
          this.tableData = this.tableData.filter((r) => r.id !== row.id);
        });
      }
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
