<!-- 내 문의사항 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6>손님관리</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center"> 
             
              <div class="col-3 text-left mb-2">  
                <base-input type="file" @change="readFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" label="단체 방문 시트 업로드" /> 
              </div> 
                <div class="col-3 text-left mb-2"> 
                  <base-button type="primary" class="btn-sm ml-2" @click="write"
                  >단체 방문 시트 작성법</base-button
                  >
                </div>
              </div>
              <modal v-model:show="modalWrite" size="md">
                <template v-slot:header>
                  <h6 class="modal-title pl-0">단체 방문 시트 작성법</h6>
                </template> 
                ** 첫번째 sheet에 작성합니다.<br><br>
                ** 첫번째 열에는 이름을 입력합니다.<br><br>
                ** 두번째 열에는 폰번호를 입력합니다(예시 010-0000-0000)<br><br>
                <div class="text-red">** 이외에는 아무것도 적지 않습니다!!!</div><br><br>
              </modal>
              <div class="row align-items-center">                
              <div class="col text-left">
                  
                <input 
                 type="text" 
                 size="10"
                 name="guest_name"
                 placeholder="이름"
                 v-model="name"
                >
                <input 
                 class="ml-2"
                 type="text" 
                 size="20"
                 name="guest_phonenumber"
                 placeholder="전화번호"
                 v-model="enc_phone_mobile"
                >
       
                <!-- New Button -->
                <base-button type="primary" class="btn-sm ml-2" @click="handleAdd"
                  >추가</base-button
                >
                <base-button type="primary" class="btn-sm ml-2" @click="sendAll"
                  >출입증발송</base-button
                >
                  
                  <!-- End New Button -->
              </div>
              </div>
            </div>
            <!-- End Search, New Button -->
            
            <!-- modal -->
            <edit-modal
              ref="editModal"
              @addDone="onAddDone"
              @editDone="onEditDone"
            />
            <!-- List -->

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'createdTime', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="순서"
                sortable
                min-width="50px"
                prop="no"
              >
              </el-table-column>
              <el-table-column
                label="이름"
                sortable
                min-width="100px"
                prop="name"
              >
              </el-table-column>
              <el-table-column
                label="전화번호"
                sortable
                min-width="150px"
                prop="enc_phone_mobile"
              >
              </el-table-column>
               
              <el-table-column
                label="action"
                min-width="130px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex">
                  <base-button
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="handleEdit(row)"
                  >
                    수정
                  </base-button>
                  <base-button
                    class="remove btn-link text-white"
                    type="danger"
                    size="sm"
                    @click.stop="handleDelete(row)"
                  >
                    삭제 
                  </base-button>
                </div>
              </el-table-column>
              <el-table-column
                label="발송"
                min-width="130px"
                align="right"
                v-slot="{ row }"
              >
                <div class="d-flex">
                  <base-button
                    v-if="row.sending"
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="resend(row)"
                  >
                    재발송
                  </base-button>
                  <base-button
                    v-else
                    class="edit"
                    type="warning"
                    size="sm"
                    @click.stop="send(row)"
                  >
                    발송
                  </base-button>
                 
                </div>
              </el-table-column>
              <el-table-column
                label="출입일시"
                sortable
                min-width="150px"
                prop="phone_number"
              >
              </el-table-column>
            </el-table>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                @page-change="getDataFromApi"
                :rows-per-page="perPage"
                :total="total"
              />
            </div>
            <!-- End Pagination -->
            <div class="col text-left mb-3 ml-3">
            <input 
            type="text" 
            size="10"
            name="guest_name"
            placeholder="이름"
            v-model="query.name"
          /> 
       
                  <!-- New Button -->
            <base-button type="primary" class="btn-sm ml-2" @click="search"
              >찾기</base-button
            >
          </div>
          </div>  
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/EditModalGuest.vue";
import Modal from "@/components/Modal";
import * as api from "@/api"; 
import XLSX from "xlsx";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
    Modal,
  },
  // 데이터 객체 추가
  data() {
    return {
      tableData: [], // 레코드셋
      name: "",
      enc_phone_mobile: "",
      query: {
        name: ""
      },
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      modalWrite: false,
      edit: false, 
      excelData: [],
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },

  // 함수 추가
  methods: {
    write() {
      this.modalWrite = true;
    },
    // api 호출
    getDataFromApi(page = 1) { 
      let inv_id = this.$route.query.inv_id;
      const url = `service/myinformation/guest?page=${page}&inv_id=${inv_id}&name=${this.query.name}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;  
        for(const row of d) { 
          if(row.pass_id) {
            row.sending = true;
          }
        }
        this.tableData = d;
        this.total = data.total;
      });
    }, 
    // 등록 모달
    handleAdd() { 
      const data = {};
      data.name = this.name;
      data.enc_phone_mobile = this.enc_phone_mobile;
      data.phone_tail = this.enc_phone_mobile.substr(-4);
      data.inv_id = this.$route.query.inv_id;
      api.post("service/myinformation/guest2", { data }).then((response) => {   
        data.guest_id = response.data.row;
        api.post("service/myinformation/guest", { data }).then((response) => {      
          this.name = "";
            this.enc_phone_mobile = "";  
            this.getDataFromApi(1);  
        }); 
      });
      let inv_id =  this.$route.query.suid;
      const url = `service/myinformation/entry/${inv_id}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data2 = response.data;
        const d = data2.data;  
        const data = {};
        data.guest_number = d.guest_number + 1;
        api.put(`service/myinformation/entry/${inv_id}`, { data }).then(() => {     
        }); 
      });  

    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 삭제하기
    handleDelete(row) {
      if (confirm("삭제하시겠습니까?")) {

        api.delete_(`service/myinformation/guest2/${row.guest_id}`).then(() => { 
          api.delete_(`service/myinformation/guest/${row.id}`).then(() => {
            this.tableData = this.tableData.filter((r) => r.id !== row.id);
          });
        });
       
        let inv_id = this.$route.query.suid;
        const url = `service/myinformation/entry/${inv_id}`;
        api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data2 = response.data;
        const d = data2.data;  
        const data = {};
        data.guest_number = d.guest_number - 1; 
        api.put(`service/myinformation/entry/${inv_id}`, { data }).then(() => {    
         
        }); 
      }); 
      }
    },
    search() {
      this.getDataFromApi();
    }, 
    resend(row) { 
      const data= {}
      data.pass_id = row.pass_id;
      api.get(`service/myinformation/accesswow/${row.pass_id}`, { data }).then((response) => {
        if(response.data.data == "OK") {
          alert("재발송에 성공하였습니다."); 
        } 
      });
    },
    send(row) {
      let inv_id = this.$route.query.suid;
      const url = `service/myinformation/entry/${inv_id}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data2 = response.data.data; 
        const data = {};
        data.expire_dt = data2.expire_dt;
        data.valid_dt = data2.valid_dt; 
        data.issue_dt = data2.open_dt;
        data.valid_yn = "Y";
        data.expire_cnt = 1;
        data.target_id = row.guest_id;
        data.enc_phone_mobile = row.enc_phone_mobile;
        data.place = data2.place;
        data.title = data2.title;  
        api.post("service/myinformation/accesswow", { data }).then((response) => {   
            data.pass_id = response.data.data;
            api.post("service/myinformation/access", { data }).then((res) => {  
              if(res.data) {                
                data.access_id = res.data.data.id;
                api.put(`service/myinformation/accesswow/${row.id}`, { data }).then(() => {
                  alert("발송에 성공하였습니다.");
                  this.getDataFromApi();
                });
              }
            });   
        }); 
      }); 
    },
    sendAll() { 
      let inv_id = this.$route.query.inv_id;
      const url = `service/myinformation/guest?inv_id=${inv_id}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;  
        this.tableData = d;
        let inv_id2 = this.$route.query.suid;
        let i = 0;
        for(const row of d) { 
          const url2 = `service/myinformation/entry/${inv_id2}`;
          api.get(url2).then((response) => {
            if (!api.isSuccess(response)) {
            return;
            }
            const data2 = response.data.data; 
            const data = {};
            data.expire_dt = data2.expire_dt;
            data.valid_dt = data2.valid_dt; 
            data.issue_dt = data2.open_dt;
            data.valid_yn = "Y";
            data.expire_cnt = 1;
            data.target_id = row.guest_id; 
            data.enc_phone_mobile = row.enc_phone_mobile;
            data.place = data2.place;
            data.title = data2.title;  
            api.post("service/myinformation/accesswow", { data }).then((response) => {   
              data.pass_id = response.data.data;
              api.post("service/myinformation/access", { data }).then((res) => { 
                if(res.data) {                
                  data.access_id = res.data.data.id;
                  api.put(`service/myinformation/accesswow/${row.id}`, { data }).then(() => {
                    i++;
                    if(i == d.length) { 
                      alert("발송에 성공하였습니다.");
                      this.getDataFromApi();  
                    }                  
                  });
                }
               }); 
            });  
          });  
        }
       
      });
      
    },
    readFile(event) {
      const file = event.target.files[0]; 
      const reader = new FileReader(); 
      reader.onload = event => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; 
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Excel 데이터를 배열에 할당
        this.excelData = jsonData; 
        
        if (this.excelData.length > 0) {
          let i = 0;
          for (const row of this.excelData) { 
            i++;
            const data = {};
            data.name = row[0];
            data.enc_phone_mobile = row[1];
            data.phone_tail = data.enc_phone_mobile.substr(-4);
            data.inv_id = this.$route.query.inv_id;
            api.post("service/myinformation/guest2", { data }).then((response) => {           
             
              data.guest_id = response.data.row;
              api.post("service/myinformation/guest", { data }).then(() => {           
              this.name = "";
              this.enc_phone_mobile = ""; 
              this.getDataFromApi(1);
            }); 
            });
            if(i == this.excelData.length) {
            let inv_id = this.$route.query.suid;
            const url = `service/myinformation/entry/${inv_id}`;
            api.get(url).then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data2 = response.data;
              const d = data2.data;  
              const data = {};
              data.guest_number = d.guest_number + this.excelData.length; 
              api.put(`service/myinformation/entry/${inv_id}`, { data }).then(() => {     
              }); 
            });   
          }
          }
        } 
      }; 
      reader.readAsArrayBuffer(file);
   } 
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
