<!-- 공지게시판 관리 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">공지게시판</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <!-- End Search, New Button -->
              <!-- modal -->

              <!-- List -->
              <div class="row ml-2">
                <div class="ml-2">
                  전체: {{ total }}, 수신: {{ read }}, 미수신: {{ unread }},
                  권한없음: {{ unauth }}
                </div>
                <div class="col mr-2 text-right">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click.prevent="goCal()"
                    >월별보기</base-button
                  >
                </div>
              </div>
              <div class="card-body mx-0 px-0" v-if="pc">
                <el-table
                  class="table-responsive table"
                  :data="tableData"
                  :default-sort="{ prop: 'no', order: 'ascescending' }"
                  header-row-class-name="thead-light"
                >
                  <el-table-column
                    label="번호"
                    sortable
                    min-width="80px"
                    prop="no"
                  >
                  </el-table-column>
                  <el-table-column
                    label="제목"
                    sortable
                    min-width="250px"
                    prop="title"
                  >
                    <template v-slot="{ row }">
                      <span class="clickable" @click="goContent(row)">
                        <div class="font-weight-600">
                          <p v-html="row.title"></p>
                        </div>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="수신처"
                    sortable
                    min-width="100px"
                    prop="receivedTeam"
                  >
                  </el-table-column>
                  <el-table-column
                    label="수신지부"
                    sortable
                    min-width="100px"
                    prop="receivedLocal"
                  >
                  </el-table-column>
                  <el-table-column
                    label="발신처"
                    sortable
                    min-width="100px"
                    prop="sendTeam"
                  >
                  </el-table-column>
                  <el-table-column
                    label="날짜"
                    sortable
                    min-width="100px"
                    prop="startDate"
                  >
                  </el-table-column>
                  <el-table-column
                    label="조회"
                    sortable
                    min-width="100px"
                    prop="contentHit"
                  >
                  </el-table-column>
                  <el-table-column
                    label="댓글/대댓글"
                    sortable
                    min-width="120px"
                    prop="commentHit2"
                  >
                  </el-table-column>
                  <el-table-column
                    label="수신"
                    sortable
                    min-width="100px"
                    prop="isRead"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <div class="card-body mx-0 px-0" v-else>
                <table align="center" width="100%">
                  <ul class="list-group list-group-flush list my--3">
                    <li
                      v-for="row in tableData"
                      :key="row.id"
                      class="list-group px-0"
                    >
                      <tr class="list-group-item">
                        <p>번호: {{ row.no }}</p>
                        <span class="clickable" @click="goContent(row)">
                          <div class="font-weight-600">
                            제목: <span v-html="row.title"></span>
                          </div>
                        </span>
                        <p class="mt-2">수신처: {{ row.receivedTeam }}</p>
                        <p class="mt-2">수신지부: {{ row.receivedLocal }}</p>
                        <p>발신처: {{ row.sendTeam }}</p>

                        <p>날짜: {{ row.startDate }}</p>
                        <p>조회수: {{ row.contentHit }}</p>
                        <p>댓글수: {{ row.commentHit2 }}</p>
                        <p>수신여부: {{ row.isRead }}</p>
                      </tr>
                    </li>
                  </ul>
                </table>
              </div>
              <!-- End List -->
              <!-- Pagination -->
              <div class="card-footer py-4 d-flex justify-content-center">
                <common-pagination
                  @page-change="getDataFromApi"
                  :rows-per-page="perPage"
                  :total="total"
                />
              </div>
              <!-- End Pagination -->
              <div class="row align-items-center">
                <div class="form-check col-sm-2 mr-1 mb-4 text-right">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="fcustomCheck1"
                    @click="detail()"
                    check=""
                  />
                  상세검색
                </div>
                <div class="col-sm-2 mr-1">
                  <base-input
                    @change="getDataFromApi()"
                    placeholder="제목"
                    v-model="query.title"
                    @keyup.enter.prevent="getDataFromApi()"
                  />
                </div>

                <div class="col-sm-2 mr-1" v-if="isDetail">
                  <base-input
                    @change="getDataFromApi()"
                    placeholder="수신처"
                    v-model="query.receivedTeam"
                    @keyup.enter.prevent="getDataFromApi()"
                  />
                </div>
                <div class="col-sm-2 mr-1 mb-4" v-if="isDetail">
                  <input
                    @change="getDataFromApi()"
                    type="date"
                    class="form-control col"
                    placeholder="날짜(YYYY-MM-DD)"
                    v-model="query.startDate"
                  />
                </div>
                <div class="col-sm-2 mb-4" v-if="isDetail">
                  <preset-select
                    placeholder="수신여부"
                    v-model="query.read"
                    :choices="[
                      ['전체', '전체'],
                      ['미수신', '미수신'],
                      ['읽음', '수신'],
                    ]"
                  />
                </div>
                <div class="col-sm-2 mb-4">
                  <base-button
                    class="btn-md"
                    type="secondary"
                    @click.prevent="getDataFromApiFromSearch(1)"
                    >검색</base-button
                  >
                </div>
                <div class="col-sm-2 mb-4">
                  <base-button
                    class="btn-md"
                    type="secondary"
                    @click.prevent="reset()"
                    >초기화</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import moment from "moment";
import * as api from "@/api";
import PresetSelect from "@/components/Inputs/PresetSelect";
import Swal from "sweetalert2";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    PresetSelect,
  },
  computed: {
    content() {
      return this.content.split("\n").join("<br>");
    },
  },

  // 데이터 객체 추가
  data() {
    return {
      tableData: [], // 레코드셋
      query: {
        // 검색 쿼리
        title: "",
        receivedTeam: "",
        startDate: "",
        read: "",
      },
      newData: {
        // 새 등록 데이터
      },
      inputPossible: false,
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      read: 0,
      unread: 0,
      unauth: 0,
      position: "",
      isDetail: false,
      pc: true,
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
  },
  // 함수 추가
  methods: {
    detail() {
      this.isDetail = !this.isDetail;
    },
    goCal() {
      this.$router.push({
        name: "공지게시판(월별보기)",
      });
    },
    reset() {
      this.query.title = "";
      this.query.receivedTeam = "";
      this.query.startDate = "";
      this.getDataFromApi();
    },
    getContent(content) {
      return content.split("\n").join("<br>");
    },
    // api 호출
    getDataFromApi(page = 1) {
      let a = window.innerWidth;
      let b = window.innerHeight;
      if (a > b && a > 700) {
        this.pc = true;
      } else {
        this.pc = false;
      }
      const token = api.Auth.tokenContent;
      let local = token.local;

      const url2 = `service/myinformation/edu?email=${token.email}`;
      api.get(url2).then((response4) => {
        if (!api.isSuccess(response4)) {
          return;
        }
        const data4 = response4.data;
        const eduStudent = [];
        for (const member of data4.data) {
          for (const student of member.groups) {
            for (const item of student.students) {
              if (eduStudent.indexOf(item) < 0) {
                eduStudent.push(item);
              }
            }
          }
        }
        if (
          eduStudent.includes(token.memberNo) &&
          !token.local.includes("국제") && token.grade === "일반회원"
        ) {
          this.$router.push({
            name: "나누기",
          });
        }
        api.get(`service/myinformation/member2`).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const today = moment();
          if (token.grade === "일반회원") {
            this.position = "일반회원";
          } else {
            for (const row of response.data.data.serviceDetail) {
              if (
                row.srv_state === "정상" &&
                !row.srv_duty.includes("행복") &&
                !row.srv_duty.includes("특위") &&
                moment(row.srv_return_dt) >= today &&
                moment(row.srv_appoint_dt) <= today
              ) {
                if (row.srv_position === "지부장") {
                  this.position = "지부장";
                } else if (row.srv_position === "지회장") {
                  this.position = "지회장";
                } else if (row.srv_position === "모둠장") {
                  this.position = "모둠장";
                }
              }
            }
            if (this.position === "") {
              this.position = "전법회원";
            }
          }

          const url = `service/myinformation/noticeService2?bound=${this.perPage}&page=${page}&title=${this.query.title}&receivedTeam=${this.query.receivedTeam}&startDate=${this.query.startDate}&read2=${this.query.read}&memberNo=${token.memberNo}`;
          api.get(url).then((response3) => {
            if (!api.isSuccess(response3)) {
              return;
            }
            const data3 = response3.data;
            const d = data3.data;
            this.read = 0;
            this.unread = 0;
            this.unauth = 0;
            if (data3.rows > 0) {
              for (const row2 of d) {
                if (row2.startDate) {
                  row2.startDate = moment(row2.startDate).format("YYYY-MM-DD");
                } else {
                  row2.startDate = "상시";
                }
                if (row2.receivedTeam.includes("전체회원")) {
                  if (!row2.receivedLocal || row2.receivedLocal == "") {
                    row2.isReceived = true;
                  } else if (row2.receivedLocal.includes(local)) {
                    row2.isReceived = true;
                  } else {
                    row2.isReceived = false;
                  }
                } else if (row2.receivedTeam.includes(this.position)) {
                  if (!row2.receivedLocal || row2.receivedLocal == "") {
                    row2.isReceived = true;
                  } else if (row2.receivedLocal.includes(local)) {
                    row2.isReceived = true;
                  } else {
                    row2.isReceived = false;
                  }
                } else if (row2.receivedTeam.includes("전법회원")) {
                  if (token.grade === "전법회원") {
                    if (!row2.receivedLocal || row2.receivedLocal == "") {
                      row2.isReceived = true;
                    } else if (row2.receivedLocal.includes(local)) {
                      row2.isReceived = true;
                    } else {
                      row2.isReceived = false;
                    }
                  } else if (token.grade === "일반회원") {
                    row2.isReceived = false;
                  }
                } else {
                  row2.isReceived = false;
                }
                if (row2.isReceived === true) {
                  if (row2.read.includes(token.memberNo)) {
                    row2.isRead = "수신";
                    this.read++;
                  } else {
                    row2.isRead = "미수신";
                    this.unread++;
                  }
                } else if (row2.isReceived === false) {
                  row2.isRead = "권한없음";
                  this.unauth++;
                }
                if (row2.receivedTeam.length > 0) {
                  let item2 = "";
                  let i = 0;
                  for (const item of row2.receivedTeam) {
                    if (i === 0) {
                      item2 = item;
                    } else {
                      item2 = item2 + ", " + item;
                    }
                    i++;
                  }
                  row2.receivedTeam = item2;
                }
                if (row2.receivedLocal) {
                  if (row2.receivedLocal.length > 0) {
                    let item2 = "";
                    let i = 0;
                    for (const item of row2.receivedLocal) {
                      if (i === 0) {
                        item2 = item;
                      } else {
                        item2 = item2 + ", " + item;
                      }
                      i++;
                    }
                    row2.receivedLocal = item2;
                  }
                }
                let commentHit4 = row2.commentHit2.reduce(function add(
                  sum,
                  currValue
                ) {
                  return sum + currValue;
                },
                0);
                let commentHit5 = 0;
                if (row2.double) {
                  let i = 0;
                  for (const item of row2.double) {
                    i = i + item.length;
                  }
                  commentHit5 = i;
                }
                row2.commentHit2 = commentHit4 + "/" + commentHit5;
              }
            }
            this.tableData = d;
            this.total = data3.total;
          });
        });
      });
    },
    getDataFromApiFromSearch(page = 1) {
      let a = window.innerWidth;
      let b = window.innerHeight;
      if (a > b && a > 700) {
        this.pc = true;
      } else {
        this.pc = false;
      }
      const token = api.Auth.tokenContent;
      let local = token.local;

      api.get(`service/myinformation/member2`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const today = moment();
        if (token.grade === "일반회원") {
          this.position = "일반회원";
        } else {
          for (const row of response.data.data.serviceDetail) {
            if (
              row.srv_state === "정상" &&
              !row.srv_duty.includes("행복") &&
              !row.srv_duty.includes("특위") &&
              moment(row.srv_return_dt) >= today &&
              moment(row.srv_appoint_dt) <= today
            ) {
              if (row.srv_position === "지부장") {
                this.position = "지부장";
              } else if (row.srv_position === "지회장") {
                this.position = "지회장";
              } else if (row.srv_position === "모둠장") {
                this.position = "모둠장";
              }
            }
          }
          if (this.position === "") {
            this.position = "전법회원";
          }
        }

        const url = `service/myinformation/noticeService2?bound=${this.perPage}&page=${page}&title=${this.query.title}&receivedTeam=${this.query.receivedTeam}&startDate=${this.query.startDate}&read2=${this.query.read}&memberNo=${token.memberNo}`;
        api.get(url).then((response3) => {
          if (!api.isSuccess(response3)) {
            return;
          }
          const data3 = response3.data;
          const d = data3.data;
          const d2 = new Array();

          if (data3.rows > 0) {
            let z = 0;
            for (const row2 of d) {
              if (row2.startDate) {
                row2.startDate = moment(row2.startDate).format("YYYY-MM-DD");
              } else {
                row2.startDate = "상시";
              }
              if (row2.receivedTeam.includes("전체회원")) {
                if (!row2.receivedLocal || row2.receivedLocal == "") {
                  row2.isReceived = true;
                } else if (row2.receivedLocal.includes(local)) {
                  row2.isReceived = true;
                } else {
                  row2.isReceived = false;
                }
              } else if (row2.receivedTeam.includes(this.position)) {
                if (!row2.receivedLocal || row2.receivedLocal == "") {
                  row2.isReceived = true;
                } else if (row2.receivedLocal.includes(local)) {
                  row2.isReceived = true;
                } else {
                  row2.isReceived = false;
                }
              } else if (row2.receivedTeam.includes("전법회원")) {
                if (token.grade === "전법회원") {
                  if (!row2.receivedLocal || row2.receivedLocal == "") {
                    row2.isReceived = true;
                  } else if (row2.receivedLocal.includes(local)) {
                    row2.isReceived = true;
                  } else {
                    row2.isReceived = false;
                  }
                } else if (token.grade === "일반회원") {
                  row2.isReceived = false;
                }
              } else {
                row2.isReceived = false;
              }
              if (row2.isReceived === true) {
                if (row2.read.includes(token.memberNo)) {
                  row2.isRead = "수신";
                  this.read++;
                } else {
                  row2.isRead = "미수신";
                  this.unread++;
                }
              } else if (row2.isReceived === false) {
                row2.isRead = "권한없음";
                this.unauth++;
              }
              if (row2.receivedTeam.length > 0) {
                let item2 = "";
                let i = 0;
                for (const item of row2.receivedTeam) {
                  if (i === 0) {
                    item2 = item;
                  } else {
                    item2 = item2 + ", " + item;
                  }
                  i++;
                }
                row2.receivedTeam = item2;
              }
              if (row2.receivedLocal) {
                if (row2.receivedLocal.length > 0) {
                  let item2 = "";
                  let i = 0;
                  for (const item of row2.receivedLocal) {
                    if (i === 0) {
                      item2 = item;
                    } else {
                      item2 = item2 + ", " + item;
                    }
                    i++;
                  }
                  row2.receivedLocal = item2;
                }
              }
              let commentHit4 = row2.commentHit2.reduce(function add(
                sum,
                currValue
              ) {
                return sum + currValue;
              },
              0);
              let commentHit5 = 0;
              if (row2.double) {
                let i = 0;
                for (const item of row2.double) {
                  i = i + item.length;
                }
                commentHit5 = i;
              }
              row2.commentHit2 = commentHit4 + "/" + commentHit5;
              if (this.query.read === "미수신") {
                if (row2.isRead === "미수신") {
                  const temp = {
                    no: z + 1,
                    commentHit2: row2.commentHit2,
                    receivedLocal: row2.receivedLocal,
                    isRead: row2.isRead,
                    title: row2.title,
                    receivedTeam: row2.receivedTeam,
                    sendTeam: row2.sendTeam,
                    startDate: row2.startDate,
                    contentHit: row2.contentHit,
                  };
                  d2.push(temp);
                }
              } else {
                const temp = {
                  no: z + 1,
                  commentHit2: row2.commentHit2,
                  receivedLocal: row2.receivedLocal,
                  isRead: row2.isRead,
                  title: row2.title,
                  receivedTeam: row2.receivedTeam,
                  sendTeam: row2.sendTeam,
                  startDate: row2.startDate,
                  contentHit: row2.contentHit,
                };
                z++;
                d2.push(temp);
              }
            }
          }
          this.tableData = d2;
          this.total = data3.total;
        });
      });
    },
    goContent(row) {
      if (row.isReceived) {
        const token = api.Auth.tokenContent;
        let data = {};
        if (!row.read.includes(token.memberNo)) {
          row.read.push(token.memberNo);
          data.read = row.read;
        }
        if (row.contentHit) {
          data.contentHit = row.contentHit + 1;
        } else {
          data.contentHit = 1;
        }
        api
          .put(`service/myinformation/noticeManage/${row.id}`, { data })
          .then(() => {});
        this.$router.push({
          name: "댓글 쓰기",
          query: {
            suid: row.id,
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "읽을 권한이 없습니다.",
        });
      }
    },
    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.query.value = "";
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 삭제하기
    handleDelete(row) {
      if (confirm("삭제하시겠습니까?")) {
        const data = {};
        data.state = 0;
        api
          .put(`service/myinformation/noticeManage/${row.id}`, { data })
          .then(() => {
            this.tableData = this.tableData.filter((r) => r.id !== row.id);
          });
      }
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
